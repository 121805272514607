<template>
  <header
    :class="productName == PRODUCT_CODES.firefighter ? 'tax-navy-blue' : ''"
  >
    <div class="lighten-5 my-3" style="margin-left:10%;margin-right:10%">
      <v-row>
        <v-col :md="2" :sm="2" :xs="12">
          <nuxt-link to="/"
            ><img
              style="height: 60px;"
              alt="logo"
              src="../../assets/AW-logos/logo@2x.png"
          /></nuxt-link>
        </v-col>
        <v-col :md="8" :xs="0" :sm="8"> </v-col>
        <v-col
          v-if="isLoginPage"
          :md="2"
          :xs="0"
          :sm="2"
          class="my-auto mx-auto"
        >
          <button
            v-if="!$store.state.user.state.loggedIn"
            style="width: 7rem !important;"
            class="contact-btn index-page btn-yellow text-black py-0 px-0 rounded-full appearance-none"
            @click="redirectToRegisterPage()"
          >
            Register
          </button>
        </v-col>
        <v-col v-if="$store.state.user.state.loggedIn" :md="2" :xs="0" :sm="2">
          <component
            :is="menuOptionsComponent"
            :redirect-to-account-settings="redirectToAccountSettings"
            :redirect-to-documents="redirectToDocuments"
            :logout-user="logoutUser"
            :read-notification="readNotification"
            :isOnboarding="true"
          />

          <component
            :is="menuOptionsMobileComponent"
            :show-menu="showMenu"
            :redirect-to-account-settings="redirectToAccountSettings"
            :redirect-to-documents="redirectToDocuments"
            :logout-user="logoutUser"
            :read-notification="readNotification"
            :isOnboarding="true"
          />
        </v-col>
      </v-row>
    </div>
  </header>
</template>

<script>
import { logOut, callGAEvent } from '~/plugins/utils';
import { readUserNotification } from '~/plugins/apiFunctions';
import MenuOptionsMobile from '~/components/Header/MobileView/MenuOptions.vue';
import { PRODUCT_CODES } from '~/constants';

export default {
  components: {
    MenuOptionsMobile
  },
  data() {
    return {
      showMenu: false,
      isLoginPage:
        this.$router.history.current.path.replace(/\/+$/, '') ===
        '/onboarding/login',
      shownoti: false,
      productName: this.$productName,
      PRODUCT_CODES
    };
  },
  watch: {
    $route() {
      this.refreshPage();
    }
  },
  computed: {
    menuOptionsComponent() {
      const menuOption =
        this.$productName == PRODUCT_CODES.avcwise
          ? 'AvcMenuOptions'
          : 'MenuOptions';

      return () => import(`~/components/Header/${menuOption}.vue`);
    },
    menuOptionsMobileComponent() {
      const menuOption =
        this.$productName == PRODUCT_CODES.avcwise
          ? 'AvcMenuOptions'
          : 'MenuOptions';

      return () => import(`~/components/Header/MobileView/${menuOption}.vue`);
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    callGAEvent,
    resizeHandler() {
      if (window.innerWidth >= 1264) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }

      if (window.innerHeight > 768) {
        this.shownoti = false;
      }
    },
    redirectToDocuments() {
      this.$router.push({
        path: '/dashboard/documents',
        name: 'dashboard-documents'
      });
      callGAEvent(this.$gtag, {
        eventName: 'documents_btn',
        category: 'btn_click'
      });
    },
    redirectToEmploymentInfo() {
      this.$router.push({
        path: '/onboarding/employment-info',
        name: 'onboarding-employment-info'
      });
    },
    async logoutUser() {
      const that = this;
      await logOut(that);
    },
    async redirectToAccountSettings() {
      await this.$router.push({
        path: '/dashboard/settings',
        name: 'dashboard-settings'
      });
    },
    getName() {
      if (
        this.$store.state.user.personalInfo.firstName.length +
          this.$store.state.user.personalInfo.lastName.length >
        20
      ) {
        return (
          `${this.$store.state.user.personalInfo.firstName} ${this.$store.state.user.personalInfo.lastName}`.substring(
            0,
            20
          ) + '..'
        );
      } else {
        return `${this.$store.state.user.personalInfo.firstName} ${this.$store.state.user.personalInfo.lastName}`;
      }
    },
    async readNotification(id) {
      await readUserNotification(id);
      this.$store.commit('readNotification', id);
    },
    refreshPage() {
      this.isLoginPage =
        this.$router.history.current.path.replace(/\/+$/, '') ===
        '/onboarding/login';
    },
    redirectToRegisterPage() {
      if (this.$productName == PRODUCT_CODES.avcwise) {
        this.$router.push('/onboarding/redeem-voucher');
      } else {
        this.$router.push('/onboarding/personal-info');
      }
      callGAEvent(this.$gtag, {
        eventName: 'register_btn',
        category: 'btn_click'
      });
    }
  }
};
</script>

<style>
.navbar.vue-fixed-header--isFixed {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}

.nav {
  background-color: #333;
  overflow: hidden;
  color: #000;
  z-index: 0;
}

.v-card__title {
  word-break: break-word !important;
}

.nav-btn {
  border-radius: 23.5px;
  border: solid 1px #ffffff;
  color: #ffffff;
}

.icon-default-size {
  width: 24px;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
